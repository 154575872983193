<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Perform each of the unit conversions shown below:</p>
      <div v-for="question in questions" :key="question.inputName">
        <p class="mb-2">
          {{ question.textPreQuantity }}
          <number-value :value="getNumberValue(question.number)" />
          <stemble-latex :content="question.textPostQuantity" />
        </p>
        <calculation-input
          v-model="inputs[question.inputName]"
          :disabled="!allowEditing"
          class="mb-4"
          :append-text="question.toUnits"
          outlined
        />
      </div>
      <p class="mb-4">
        d) In 2 or 3 sentences, explain how you convert quantities from one unit to another in the
        examples above.
      </p>
      <div>
        <s-textarea v-model="inputs.explainedResponse" class="mb-4" outlined />
      </div>
    </v-form>
    <ai-loading-overlay v-if="isSubmitting" />
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';
import STextarea from '@/common/components/STextarea.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'DetermineUnitConversions1',
  components: {
    AiLoadingOverlay,
    STextarea,
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        explainedResponse: null,
      },
      questions: [
        {
          textPreQuantity: 'a) ',
          textPostQuantity: '$\\text{kg/m}^3$ to $\\text{g/L}$',
          toUnits: '$\\text{g/L}$',
          number: 'number1',
          inputName: 'input1',
        },
        {
          textPreQuantity: 'b) ',
          textPostQuantity: '$\\text{nm}^2$ to $\\text{m}^2$',
          toUnits: '$\\text{m}^2$',
          number: 'number2',
          inputName: 'input2',
        },
        {
          textPreQuantity: 'c) ',
          textPostQuantity: '$\\text{cm}^{-1}$ to $\\text{m}^{-1}$',
          toUnits: '$\\text{m}^{-1}$',
          number: 'number3',
          inputName: 'input3',
        },
      ],
    };
  },
  computed: {
    number1() {
      return this.taskState.getValueBySymbol('number1').content;
    },
    number2() {
      return this.taskState.getValueBySymbol('number2').content;
    },
    number3() {
      return this.taskState.getValueBySymbol('number3').content;
    },
  },
  methods: {
    getNumberValue(numberPropertyName) {
      // Access the computed property based on the provided numberPropertyName
      return this[numberPropertyName];
    },
  },
};
</script>
